import React from 'react';
import '@google/model-viewer/dist/model-viewer';
import * as cuboidUsdz from '../assets/img/cuboid2x2x5.usdz';
import * as cuboidGltf from '../assets/img/cuboid4x5x9.gltf';

class CuboidPage extends React.Component {

    constructor(props) {
        super(props);
        console.log(props);

        this.state = {
            url: 'https://ar.mowetec.com/v1/cuboid/',
            length: '1', width: '1', heigth: '1', unit: 'cm'
        };
        // this.state = {
        //     url: 'http://localhost:3000/v1/cuboid/',
        //     length: '1', width: '1', heigth: '1', unit: 'cm'
        // };

        if (props.match.params.l) {
            this.state.length = props.match.params.l;
        }
        if (props.match.params.w) {
            this.state.width = props.match.params.w;
        }
        if (props.match.params.h) {
            this.state.heigth = props.match.params.h;
        }
        if (props.match.params.u) {
            this.state.unit = props.match.params.u;
        }

        this.state.url = this.state.url +
            this.state.length + '/' + this.state.width + '/' + this.state.heigth;

        console.log(this.state);

        // this.handleChangeLength = this.handleChangeLength.bind(this);
        // this.handleChangeWidth = this.handleChangeWidth.bind(this);
        // this.handleChangeHeigth = this.handleChangeHeigth.bind(this);
        // this.handleCuboidSubmit = this.handleCuboidSubmit.bind(this);
    }

    componentDidMount = () => window.scrollTo(0, 0);

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) window.scrollTo(0, 0);
    }

    render() {
        return (
            <div>
                <h2>Ergebnis</h2>

                <model-viewer
                    // src="http://api.ar.mowetec.com:3000/v1/cuboid/2/2/5"
                    // src={this.state.url}
                    src={cuboidGltf}
                    ios-src={cuboidUsdz}
                    alt="A 3D model"
                    ar ar-modes="webxr scene-viewer quick-look"
                    ar-scale="auto"
                    auto-rotate
                    camera-controls />
            </div>
        );
    }
}

export default CuboidPage;
