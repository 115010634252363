
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import faqData from '../assets/data/faq.json';
import { Input } from 'reactstrap';

class FaqPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            faq: faqData,
            filteredFaq: faqData,
            q: ''
        };
        this.filterList = this.filterList.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
    }

    componentDidMount = () => window.scrollTo(0, 0);

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) window.scrollTo(0, 0);
    }

    filterList() {
        let faq = this.state.faq;
        let q = this.state.q;

        faq = faq.filter(function (n) {
            return n.Frage.toLowerCase().indexOf(q) != -1; // returns true or false
        });
        this.setState({ filteredFaq: faq });
    }

    handleFilter(event) {
        const q = event.target.value.toLowerCase();
        this.setState({ q }, () => this.filterList());
    }

    render() {
        return (
            <>
                <br />
                <div className="row">
                    <div className="col">
                        <div className="card shadow-sm">
                            <div className="card-header text-center bg-warning">
                                <div className="row">
                                    <div className="col"><FontAwesomeIcon icon={faQuestionCircle} /> Häufig gestellte Fragen</div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <Input
                                            placeholder="Suchbegriff..."
                                            type="text"
                                            value={this.state.filter}
                                            onChange={this.handleFilter} />
                                    </div>
                                </div>
                                <br />
                                {this.state.filteredFaq.map((faq, index) => (
                                    <div>
                                        <div className="row">
                                            <div className="col">
                                                <strong>{faq.Frage}</strong>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                {faq.Antwort}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="card-footer text-center">
                                huhu
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default FaqPage;

