import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

import Layout from './pages/Layout';
import HomePage from './pages/HomePage';
// import About from './pages/AboutPage';
// import ViewPage from './pages/ViewPage';
// import StatistikenPage from './pages/StatistikenPage';
import FaqPage from './pages/FaqPage';
import ImpressumPage from './pages/ImpressumPage';
// import DatenschutzPage from './pages/DatenschutzPage';
import CuboidPage from './pages/CuboidPage';
import CubePage from './pages/CubePage';
import { Route, Switch } from 'react-router-dom';

function App() {
  // const history = useHistory();

  return (
    <Layout>
      <Switch>
        <Route exact path='/' component={HomePage} />
        <Route exact path='/Home' component={HomePage} />
        <Route exact path='/Faq' component={FaqPage} />
        {/* 
      <Route path='/Statistik' component={StatistikenPage} />
      <Route path='/View' component={ViewPage} /> */}
        <Route path='/Cuboid/:l/:w/:h/:u' component={CuboidPage} />
        <Route path='/Cube' component={CubePage} />
        <Route exact path='/Impressum' component={ImpressumPage} />
        {/* <Route path='/About' component={About} />
      <Route path='/Privacy' component={DatenschutzPage} /> */}
      {/* </BrowserRouter> */}
      </Switch>
    </Layout>
  );
}

export default App;
