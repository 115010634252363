import React from 'react';
import {
    Collapse, Container, Navbar,
    NavbarBrand, NavbarToggler, NavItem, NavLink, Nav
} from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faChartBar, faMap, faBriefcaseMedical, faNewspaper, faEnvelope, faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faGithub } from '@fortawesome/free-brands-svg-icons';
import { BrowserRouter } from 'react-router-dom';


class NavMenu extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.collapse = this.collapse.bind(this);
        this.state = {
            isOpen: false
        };
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    collapse() {
        this.setState({
            isOpen: false
        });
    }

    render() {
        const { t, i18n  } = this.props;
        const changeLanguage = (language) => i18n.changeLanguage(language);

        const baz = () => <NavLink className="navbar-brand" tag={Link} to="/"><FontAwesomeIcon icon={faLayerGroup} /> <strong>AR View</strong></NavLink>

        return (
            <Navbar color="dark" dark expand="md" id="mainNav">
                {/* <NavbarBrand href="/">reactstrap</NavbarBrand> */}
                <NavbarBrand title="Augmented Reality">
                    <NavLink tag={Link} className="navbar-brand" to="/"><FontAwesomeIcon icon={faLayerGroup} /> <strong>AR View</strong></NavLink>
                </NavbarBrand>
                <NavbarToggler onClick={this.toggle} />
                <Collapse id="navbarResponsive" isOpen={this.state.isOpen} navbar>
                    <Nav className="ml-auto my-2 my-lg-0" navbar>
                        {/* <NavItem>
                            <NavLink title="Ablauf Ansteckungen. Grafiken." href="/Statistik" className="text-white lead"><FontAwesomeIcon icon={faChartBar} /> Statistiken</NavLink>
                        </NavItem> */}
                        <NavItem>
                            <NavLink tag={Link} title="Verbreitung nach Bundesländern." to="/Map" className="text-white"><FontAwesomeIcon icon={faMap} /> Karte</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} title="News. Neuigkeiten. Aktuelles." to="/News" onClick={this.toggle} className="text-white"><FontAwesomeIcon icon={faNewspaper} /> Aktuelle Meldungen</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink  tag={Link}title="Fragen und Antworten. Maßnahmen." to="/Faq" className="text-white"><FontAwesomeIcon icon={faQuestionCircle} /> FAQ</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink title="Was tun bei Corona-Verdacht?" href="/Verdacht" className="text-white"><FontAwesomeIcon icon={faBriefcaseMedical} /> Verdacht/Test</NavLink>
                        </NavItem>
                        <form className="form-inline">
                            {/* German */}
                            <button className="btn btn-sm btn-outline-secondary" type="button" onClick={e => changeLanguage("de")}>
                                <span className="flag-icon flag-icon-de"></span>
                            </button>
                            {/* English */}
                            <button className="btn btn-sm btn-outline-secondary" type="button" onClick={e => changeLanguage("en")}>
                                <span className="flag-icon flag-icon-gb"></span>
                            </button>
                            {/* Spanish */}
                            <button className="btn btn-sm btn-outline-secondary" type="button" onClick={e => changeLanguage("en")}>
                                <span className="flag-icon flag-icon-es"></span>
                            </button>
                            {/* French */}
                            <button className="btn btn-sm btn-outline-secondary" type="button" onClick={e => changeLanguage("en")}>
                                <span className="flag-icon flag-icon-fr"></span>
                            </button>
                            {/* Turkish */}
                            <button className="btn btn-sm btn-outline-secondary" type="button" onClick={e => changeLanguage("en")}>
                                <span className="flag-icon flag-icon-tr"></span>
                            </button>
                            {/* Greek */}
                            <button className="btn btn-sm btn-outline-secondary" type="button" onClick={e => changeLanguage("en")}>
                                <span className="flag-icon flag-icon-gr"></span>
                            </button>
                            {/* Italian */}
                            <button className="btn btn-sm btn-outline-secondary" type="button" onClick={e => changeLanguage("en")}>
                                <span className="flag-icon flag-icon-it"></span>
                            </button>
                        </form>
                    </Nav>
                </Collapse>
            </Navbar>
        );
    }
}

export default NavMenu;