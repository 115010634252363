import React from 'react';
import { Container } from 'reactstrap';
import CookieConsent from "react-cookie-consent";
import NavMenu from '../components/NavMenu';
import GoToTop from '../components/GoToTop';
import './Layout.css';
import { Link, BrowserRouter } from 'react-router-dom';

const GreyLineBottom = () => (
    <hr
        style={{
            position: "fixed",
            left: 0,
            height: 0,
            width: "100%",
            border: "2px solid grey",
            margin: 0,
            zIndex: 1030,
            bottom: 0
        }}
    />
);

const Layout = (props) => (
    <>
        <div>
            <NavMenu />
            <Container fluid>
                {props.children}
                <footer>
                    <div className="text-center">
                        <br />
                        {/* <BrowserRouter> */}
                        <Link to="/About">Über</Link> | <Link to="/Impressum">Impressum</Link> | <Link to="/Datenschutz">Datenschutz</Link><br />
                        {/* </BrowserRouter> */}
                        &copy; 2020 - MoWeTec
                        <br />
                        <br />
                    </div>
                </footer>
            </Container>
        </div>
        <GreyLineBottom />
        <GoToTop />
        <CookieConsent buttonText="OK">
            Diese Website benutzt Cookies. Wenn du die Website weiter nutzt, gehen wir von deinem Einverständnis aus.
        </CookieConsent>
    </>
);

export default Layout;
