import React from 'react';
import '@google/model-viewer/dist/model-viewer';
import * as cuboidUsdz from '../assets/img/cuboid2x2x5.usdz';
import * as cuboidGltf from '../assets/img/cuboid4x5x9.gltf';

function CubePage() {



    return (
        <div>
            <h2>Ergebnis</h2>

            <model-viewer
                // src="http://api.ar.mowetec.com:3000/v1/cuboid/8/2/5"
                // src={this.state.url}
                src={cuboidGltf}
                ios-src={cuboidUsdz}
                alt="A 3D model"
                ar ar-modes="webxr scene-viewer quick-look"
                ar-scale="auto"
                auto-rotate
                camera-controls />
        </div>
    );

}

export default CubePage;
