import React from 'react';
import './HomePage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faQuestionCircle, faChartBar, faMap,
    faBriefcaseMedical, faNewspaper
} from '@fortawesome/free-solid-svg-icons';
import { Button, Input } from 'reactstrap';

import heroImage from '../assets/img/hero-image.svg';
import dimensionsImage from '../assets/img/use-cases/image1.jpg';
import embedShopImage from '../assets/img/use-cases/image2.jpg';
import sharingImage from '../assets/img/use-cases/image3.jpg';
import { useHistory } from 'react-router-dom';

class HomePage extends React.Component { 

    constructor(props) {
        super(props);
        // this.pops.history

        this.state = { length: '', width: '', heigth: '', unit: 'cm' };

        this.handleChangeLength = this.handleChangeLength.bind(this);
        this.handleChangeWidth = this.handleChangeWidth.bind(this);
        this.handleChangeHeigth = this.handleChangeHeigth.bind(this);
        this.handleCuboidSubmit = this.handleCuboidSubmit.bind(this);
    }

    componentDidMount = () => window.scrollTo(0, 0);

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) window.scrollTo(0, 0);
    }

    handleChangeLength(event) {
        this.setState({ length: event.target.value });
    }

    handleChangeWidth(event) {
        this.setState({ width: event.target.value });
    }

    handleChangeHeigth(event) {
        this.setState({ heigth: event.target.value });
    }

    handleCuboidSubmit(event) {
        console.log('length: ' + this.state.length);
        console.log('width: ' + this.state.width);
        console.log('heigth: ' + this.state.heigth);
        console.log('unit: ' + this.state.unit);
        console.log(this.props);
        this.props.history.push("/Cuboid/" + this.state.length + "/" + this.state.width + "/" + this.state.heigth + "/" + this.state.unit)
        event.preventDefault();
    }

    render() {
        return (
            <>
                <div className="row blackBackground">
                    <div className="col-md-6">
                        <h1>Visualize dimensions in Augmented Reality</h1>
                        <h2>Take the guesswork out of shopping online.</h2>
                    </div>
                    <div className="col-md-6">
                        <img className="img-fluid" src={heroImage} alt="Icon" />
                    </div>
                </div>
                <br />
                <form onSubmit={this.handleCuboidSubmit}>
                    <div className="row">


                        <div className="col-md-3">
                            <Input
                                type="number"
                                name="length"
                                id="length"
                                placeholder="length"
                                value={this.state.length} 
                                onChange={this.handleChangeLength} 
                            />
                        </div>
                        <div className="col-md-3">
                            <Input
                                type="number"
                                name="width"
                                id="width"
                                placeholder="width"
                                value={this.state.width} 
                                onChange={this.handleChangeWidth} 
                            />
                        </div>
                        <div className="col-md-3">
                            <Input
                                type="number"
                                name="heigth"
                                id="heigth"
                                placeholder="heigth"
                                value={this.state.heigth} 
                                onChange={this.handleChangeHeigth} 
                            />
                        </div>
                        <div className="col-md-1">
                            <Input 
                                type="select" 
                                name="unit" 
                                id="unit">
                                <option>cm</option>
                                <option>m</option>
                                <option>in</option>
                                <option>ft</option>
                            </Input>
                        </div>
                        <div className="col-md-2">
                            <Button color="primary">Erstelle 3D Ansicht</Button>
                        </div>

                    </div>
                </form>
                <br />
                <div className="row">
                    <div className="col text-center">
                        <h2>Use cases</h2>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-md-4">
                        <img className="img-fluid" src={dimensionsImage} alt="Icon" />
                        <h4>Visualize dimensions of a product</h4>
                        <p>Simply put in the dimensions of any product, and a 3D box shows you exactly how it fits in your space.</p>
                    </div>
                    <div className="col-md-4">
                        <img className="img-fluid" src={embedShopImage} alt="Icon" />
                        <h4>Embed on your online store</h4>
                        <p>Paste a link into your product’s description. Customers clicking on it will launch right into AR.</p>
                    </div>
                    <div className="col-md-4">
                        <img className="img-fluid" src={sharingImage} alt="Icon" />
                        <h4>Share dimensions with anyone</h4>
                        <p>Send a prefilled link directly to friends or customers.</p>
                    </div>
                </div>
                <br />
                <div className="row greyBackground">
                    <div className="col text-center">
                        <h2>FAQ</h2>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col text-center">
                        <h2>Share</h2>
                    </div>
                </div>
                <br />
            </>
        );
    }
};

export default HomePage;

